var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "login-page page register-page" },
    [
      _c("van-notice-bar", {
        attrs: {
          wrapable: "",
          color: "#1989fa",
          background: "#ecf9ff",
          "left-icon": "info-o",
          scrollable: false,
          text: "尊敬的" + _vm.realName + "用户，您好："
        }
      }),
      _c("van-notice-bar", {
        attrs: {
          wrapable: "",
          color: "#1989fa",
          background: "#ecf9ff",
          "left-icon": "info-o",
          scrollable: false,
          text:
            "您的用户名为" +
            _vm.userName +
            "，您的初始密码为" +
            _vm.password +
            "，为了您的账号安全,请及时修改密码。"
        }
      }),
      _c(
        "van-form",
        { staticClass: "login-form", on: { submit: _vm.submit } },
        [
          _c("p", { staticClass: "form-label" }, [_vm._v(" 新密码： ")]),
          _c("van-field", {
            attrs: {
              rules: _vm.rules.password,
              label: "",
              placeholder: "请输入新密码"
            },
            model: {
              value: _vm.passwordForm.password,
              callback: function($$v) {
                _vm.$set(_vm.passwordForm, "password", $$v)
              },
              expression: "passwordForm.password"
            }
          }),
          _c("p", { staticClass: "form-label" }, [
            _vm._v(" 再次输入新密码： ")
          ]),
          _c("van-field", {
            attrs: {
              name: "validator",
              rules: _vm.rules.passwordAgain,
              label: "",
              placeholder: "请再次输入新密码"
            },
            model: {
              value: _vm.passwordForm.passwordAgain,
              callback: function($$v) {
                _vm.$set(_vm.passwordForm, "passwordAgain", $$v)
              },
              expression: "passwordForm.passwordAgain"
            }
          }),
          _c(
            "van-button",
            {
              attrs: {
                block: "",
                type: "info",
                "native-type": "submit",
                loading: _vm.loading.submit
              }
            },
            [_vm._v(" 确 定 ")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }