<template>
  <div class="login-page page register-page">
    <van-notice-bar
      wrapable
      color="#1989fa" background="#ecf9ff" left-icon="info-o"
      :scrollable="false"
      :text="`尊敬的${realName}用户，您好：`"
    />
    <van-notice-bar
      wrapable
      color="#1989fa" background="#ecf9ff" left-icon="info-o"
      :scrollable="false"
      :text="`您的用户名为${userName}，您的初始密码为${password}，为了您的账号安全,请及时修改密码。`"
    />
    <van-form class="login-form" @submit="submit">
      <p class="form-label">
        新密码：
      </p>
      <van-field v-model="passwordForm.password" :rules="rules.password" label="" placeholder="请输入新密码" />
      <p class="form-label">
        再次输入新密码：
      </p>
      <van-field v-model="passwordForm.passwordAgain" name="validator" :rules="rules.passwordAgain" label="" placeholder="请再次输入新密码" />
      <van-button block type="info" native-type="submit" :loading="loading.submit">
        确 定
      </van-button>
    </van-form>
  </div>
</template>

<script>
import { Field, Form, Button, Toast, NoticeBar, Checkbox } from 'vant'
export default {
  components: {
    [Field.name]: Field,
    [Form.name]: Form,
    [Button.name]: Button,
    [NoticeBar.name]: NoticeBar,
    [Checkbox.name]: Checkbox
  },
  data () {
    return {
      enterpriseId: this.$route.query.enterpriseId,
      userName: this.$route.query.userName,
      userId: this.$route.query.userId,
      realName: this.$route.query.realName,
      password: '123456',
      passwordForm: {
        password: '',
        passwordAgain: ''
      },
      loading: {
        submit: false
      },
      rules: {
        password: [{ required: true, message: '请输入新密码' }],
        passwordAgain: [
          { required: true, message: '请再次输入新密码' },
          { validator: this.validator, message: '两次输入密码不一致、请重新输入！' }
        ]
      }
    }
  },
  created () {
  },
  methods: {
    // 校验两次是否一致
    validator  (value, rule) {
      return value === this.passwordForm.password
    },
    // 确定
    submit () {
      this.loading.submit = true
      this.api.base.updateInfo({ password: this.passwordForm.passwordAgain, id: this._.toNumber(this.userId) }).then(result => {
        // const data = result.data.data
        Toast.success('提交成功')
        this.$router.push({ path: '/personal/information', query: { enterpriseId: this.enterpriseId } })
      }).finally(() => {
        this.loading.submit = false
      })
    }
  }
}
</script>

<style>

</style>
